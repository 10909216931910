import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios from "axios";
import ThemedSuspense from "../components/ThemedSuspense";
import { urlBase } from "../routes/api-urls";

const apiUrl = urlBase;

// create context
export const LicenseContext = React.createContext();

export const LicenseProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(true);
  const [subscriptionPlans, setPlans] = useState([]);
  const [topupPlans, setTopup] = useState([]);

  const loadProducts = useCallback(() => {
    axios
      .get(`${apiUrl}/v1/license/plan?isActive=true`, {})
      .then((response) => {
        setLoaded(true);
        setPlans(response.data.results.filter((plan) => plan.type === "SUBSCRIPTION"));
        setTopup(response.data.results.filter((plan) => plan.type === "TOP-UP"));
      })
      .catch((err) => {
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const value = useMemo(() => {
    return {
      subscriptionPlans,
      topupPlans,
    };
  }, [topupPlans, subscriptionPlans]);

  if (!isLoaded) {
    return <ThemedSuspense />;
  }

  return <LicenseContext.Provider value={value}>{children}</LicenseContext.Provider>;
};
