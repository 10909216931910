import React from "react";
import { useNavigate } from "react-router-dom";
import error_icon from "../../assets/img/error-emoji.png";
import fexoLogo from "../../assets/img/Fexo-logo.png";

function NavigateButton() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate("/dashboard");
        window.location.reload();
      }}
      className="w-56 h-12 font-medium rounded text-sm bg-btnPrimary text-white"
    >
      Go to Dashboard
    </button>
  );
}

class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      console.error("Internal Error Handler:", this.state.error);
      return (
        <div className="h-screen w-full bg-bgScreen flex flex-col justify-center items-center bg-gradient-to-br from-red-100 to-purple-200">
          <div className="text-center">
            <img src={error_icon} className="w-36 mx-auto" alt="Error Emoji" />
            <h2 className="mb-4 text-red-500 font-bold text-3xl">
              Oops! Something went wrong.
            </h2>
            <p className="text-gray-700 text-lg mb-8">
              We're experiencing an issue. Please try again later or contact support if
              the problem persists.
            </p>
          </div>
          <div className="bg-white shadow-xl rounded-lg border-2 border-red-500 px-6 py-4 w-full container max-w-5xl">
            <label className="block text-base font-semibold">
              Error Message:{" "}
              <span className="text-red-500 text-lg">{this.state?.error?.message}</span>
            </label>
            <div className="mt-2">
              <label className="block font-semibold">Error Stack:</label>
              <p className="border rounded-sm border-gray-400 px-4 py-2 mt-2 bg-gray-100 text-sm max-h-56 overflow-y-auto">
                {this.state?.error?.stack}
              </p>
            </div>
            <div className="flex justify-center items-center w-full mt-6">
              <NavigateButton />
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full p-4 text-gray-500 text-sm">
            <img src={fexoLogo} className="w-36 h-24" alt="Fexo Logo" />
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
